.loginscreen {
  position: absolute;
  top: 30%;
  left: 5%;
}

.loginscreen #content-login {
  padding: 30px;
  background-color: #fff;
  border-radius: 6px;
}

.loginscreen #footer-login {
  margin-top: 60px;
  text-align: right;
  color: #A81118;
}

.btn-primary {
  background-color: #A81118;
  border-color: #A81118;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary.active:focus {
  background-color: #A81118;
  border-color: #A81118;
  opacity: 0.8;
}

.text-red {
  color: #A81118;
}

a {
  color: #A81118;
}

.content-forgot h3, .content-forgot p {
  text-align: left;
}

a:focus, a:hover {
  color: #A81118;
  opacity: .5;
  text-decoration: none;
}
